











































import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import PostComponent from '@/components/PostComponent.vue';
import { gsap , TweenMax, TimelineMax, Bounce, Elastic, Sine} from "gsap";
import { Draggable, InertiaPlugin, ScrollTrigger } from 'gsap/all';
import { name } from '@/assets/data/Names';
import Post, { Author } from '@/models/Post';
import PostHelper from "@/helpers/PostHelper"
import SessionHelper from "@/helpers/SessionHelper"
import UserController from "@/controllers/UserController"
import SessionController from "@/controllers/SessionController"
import _ from "lodash";
import User from '@/models/User';

let postId = 0;


@Component({
  components:{
    Post:PostComponent,
  }
})
export default class UserWall extends Vue {
  @Prop({default:false}) manager!:boolean;
  @Prop({default:"dev"}) username!:string;
  hasParticiped = false;

  public get user():User | undefined{
    return UserController.users[0];
  }
  public get posts():Post[]{
    return _.flatten(UserController.users.map(u=>u.posts))
  }

  public readonly POST_HEIGHT_PX = 600;

  postStyle(post:any){
    return {
      height: `${this.POST_HEIGHT_PX}px`,
      top: `${this.POST_HEIGHT_PX * post.key+70}px`,
    }
  }

  currentPostIndex = 0;
  postCache = 3;

  public get displayedPosts(){
    let firstPost = this.currentPostIndex -1;
    let lastPost  = this.currentPostIndex + this.postCache;
    let postCount = this.posts.length;
    return this.posts
      .map((p,i)=>{return {...p, key:i}})
      .filter((post, i)=>{
         return i>=firstPost && i<=lastPost
      })
      // .map((post)=>{
      //   return {
      //     ...post,
      //     id: ( post.id + postCount - firstPost % postCount ) % postCount +firstPost
      //   }
      // })
  }

  public endPost?:Post;
  public draggables:Draggable[] = [];
  public mounted():void {
    setInterval(()=>{
      let y = gsap.getProperty("#wall", "y") as number;
      this.currentPostIndex = -Math.round(y / this.POST_HEIGHT_PX)
    },100)

  }

  get sessionName(){
    return "dev"
  }

  get session(){
    return SessionController.sessions.find(s=>s.uid == this.sessionName)
  }

  get mode(){
    return this.session?.mode || "0"
  }

  get tap(){
    return (<any>window).TapticEngine
  }

  @Watch("mode")
  onModeChange(mode:string){
    this.hasParticiped = false
    if(this.manager){
      switch(mode){
        case "0" :
          if (this.tap) {
            this.tap.selection();
          }else{

            navigator.vibrate(500)
          }
          break;
        case "1" :
          navigator.vibrate([100])
          break;
        case "2" :
          navigator.vibrate([100, 50, 100])
          break;
        case "3" :
          navigator.vibrate([100, 50, 100, 50, 100])
          break;
      }
    }


  }

  setMode(mode:string){
    if(!this.manager){
      return;
    }

    let newMode = mode;

    if(newMode == this.mode){
      newMode = "0"
    }

    SessionController.updateSession({
      ...(this.session || SessionHelper.createSession(this.sessionName)),
      mode:newMode,
      count:0
    })
  }
  

  randomIndex = _.random(0,100)
  @Watch("posts",{immediate:true})
  onPostChange() {
    console.log("onPostChange")
    this.draggables.map(d=>d.kill())
    this.draggables = Draggable.create(
      "#wall",
      {
        type:"y",
        inertia:true,
        edgeResistance:0.05,
        bounds:{
          minX:0,
          maxX:0,
          minY:-(this.posts.length-1) * this.POST_HEIGHT_PX,
          maxY:0,
        },
        
        snap: {
          y: (endValue) => {
            let endPostIndex = -Math.round(endValue / this.POST_HEIGHT_PX)+3;
            this.endPost = this.posts.find((p,i) =>  i == endPostIndex);
            let dy = InertiaPlugin.getVelocity("#wall", "y")
            let dx = InertiaPlugin.getVelocity("#wall", "x")
            let speed = Math.sqrt(dx*dx + dy*dy);
            if(this.mode != "0" && this.endPost && dy < -1000){
              let modePosts = this.user?.hackedPosts[this.mode];
              console.log(modePosts)

              if(modePosts){
                let newPost = modePosts[this.randomIndex%modePosts.length];
                this.randomIndex++;
                if(newPost){
                  this.posts[endPostIndex] = newPost;
                  // this.endPost.text = "magie";
                  // this.endPost.image_src = "https://cataas.com/cat?random="+Math.random();
                  // this.endPost.tags = "chat"

                  if(this.session && !this.hasParticiped){
                    this.hasParticiped = true;
                    SessionController.addParticipation(this.session);
                  }

                  return - endPostIndex * this.POST_HEIGHT_PX;
                

                }
              }
            }
            return endValue;
          }
        },
      }
    );
  }

  home(event:Event){
    this.setMode('0')
    gsap.killTweensOf("#wall");
    
    TweenMax.set("#wall", {y:0})
  }
}
