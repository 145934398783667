import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import UserWall from '../views/UserWall.vue'
import AuthController from '@/controllers/AuthController'


Vue.use(VueRouter)

const ifNotAuthenticated = (to:any, from:any, next:any)  => {
  if (!AuthController.isAuthenticated) {
    next()
    return
  }
  next('/admin')
}

const ifAuthenticated = (to:any, from:any, next:any) => {
  if (AuthController.isAuthenticated) {
    next()
    return
  }
  next('/auth')
}

const routes: Array<RouteConfig> = [
  {
    path: '/admin',
    name: 'Admin',
    beforeEnter: ifAuthenticated,
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue')
  },
  {
    path: '/auth',
    name: 'Auth',
    beforeEnter: ifNotAuthenticated,
    component: () => import(/* webpackChunkName: "admin" */ '../views/Auth.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: UserWall,
  },
  {
    path: '/:username',
    name: 'Home',
    component: UserWall
  },
  {
    path: '/:username/mgt',
    name: 'Home',
    component: UserWall,
    props:{manager:true}
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
