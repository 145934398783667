import Vue from 'vue';
import FirebaseService from '@/services/FirebaseService'

class AuthController{
  public events = new Vue();
  public user:firebase.User | null = null;
  public status:string = ""
  public auth!:firebase.auth.Auth;

  public get isAuthenticated(){
    return this.user != null;
  }

  async signIn(email:string, password:string) {
    this.status = "Connexion"
    try{

      let userCredentials = await this.auth.signInWithEmailAndPassword(email, password)
      this.user = userCredentials.user;
      if(!this.user){
        this.status = "Erreur de connexion"
      }else{
        this.status = ""
      }
    }
    catch(x_x){
      this.status = x_x.message
    }
  }

  async signOut() {
    await this.auth.signOut()
    this.user = null;
  }


  constructor(){
    
  }

  initialize(){
    this.auth = FirebaseService.auth;
    this.auth.onAuthStateChanged((user) => {
      this.user = user;
    })
  }

  private static _singleton:AuthController;
  static get instance():AuthController {
    if(!AuthController._singleton) {
      AuthController._singleton = Vue.observable(new AuthController());
      AuthController._singleton.initialize();
    }
    return AuthController._singleton;
  }
}

export default AuthController.instance;