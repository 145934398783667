












































import Post from '@/models/Post';
import { trim } from 'lodash';
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";

@Component({
  components:{}
})
export default class PostComponent extends Vue {
  @Prop() post!:Post;

  get picture(){
      return (this.post.image_src || 'https://picsum.photos/500/500')
  }
  get tags(){
    return this.post.tags?.split(",").map(tag=>trim(tag)) || []
  }

  public mounted():void {
    
  }

  public beforeDestroy():void {
    
  }

}
