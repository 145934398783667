import Session from '@/models/Session';


export default class SessionHelper{

  
  public static createSession(name:string):Session{
    return {
      uid:name,
      mode:"0",
      username:name,
      count:0,
    }
  }

}