import Post from '@/models/Post';
import Vue from 'vue';
import FirebaseService from '@/services/FirebaseService'
import Session from '@/models/Session';
import firebase from 'firebase';

class SessionController{

  public sessions:Session[] = [];
  public feedbacks:any[] = [];
  private readonly sessionsRef = FirebaseService.db.collection("sessions")
  private readonly feedbackRef = FirebaseService.db.collection("feedbacks")
  
  createSession(session:Session){
    this.sessionsRef.doc(session.uid).set(session, {merge:true})
  }

  deleteSession(session:Session){
    this.sessionsRef.doc(session.uid).delete()
  }

  updateSession(session:Session){
    this.sessionsRef.doc(session.uid).set(session, {merge:true})
  }

  public addParticipation(session:Session){    
    this.sessionsRef.doc(session.uid).update("count", firebase.firestore.FieldValue.increment(1));
  }
  
  public clearParticipation(session:Session){
    this.sessionsRef.doc("dev").set({count:0}, {merge:true})
      .then(()=>{console.log("ok")})
      .catch((x_x)=>{console.error(x_x)})
  }
  
  constructor(){

  }

  initialize(){
    this.sessionsRef.onSnapshot((snapshot)=>{
      this.sessions = snapshot.docs.map((doc)=>{
        return {
          ...doc.data(), 
          uid:doc.id
        } as Session
      })
    })
    this.feedbackRef.onSnapshot((snapshot)=>{
      this.feedbacks = snapshot.docs.map((doc)=>{
        return {
          ...doc.data(), 
          uid:doc.id
        } 
      })
    })
  }

  private static _singleton:SessionController;
  static get instance():SessionController {
    if(!SessionController._singleton) {
      SessionController._singleton = Vue.observable(new SessionController());
      SessionController._singleton.initialize();
    }
    return SessionController._singleton;
  }
}

export default SessionController.instance;