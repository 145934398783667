import Post from '@/models/Post';
import Vue from 'vue';
import FirebaseService from '@/services/FirebaseService'
import User from '@/models/User';

class UserController{

  public users:User[] = [];
  private readonly usersRef = FirebaseService.db.collection("users")
  
  createUser(user:User){
    this.usersRef.doc(user.uid).set(user, {merge:true})
  }

  deleteUser(user:User){
    this.usersRef.doc(user.uid).delete()
  }
  
  constructor(){

  }

  initialize(){
    this.usersRef.onSnapshot((snapshot)=>{
      this.users = snapshot.docs.map((doc)=>{
        return {
          ...doc.data(), 
          uid:doc.id
        } as User
      })
    })
  }

  private static _singleton:UserController;
  static get instance():UserController {
    if(!UserController._singleton) {
      UserController._singleton = Vue.observable(new UserController());
      UserController._singleton.initialize();
    }
    return UserController._singleton;
  }
}

export default UserController.instance;