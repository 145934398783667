import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {gsap, CSSPlugin} from 'gsap'
import ScrollToPlugin from "gsap/ScrollToPlugin"
import { ScrollTrigger, Draggable, InertiaPlugin} from 'gsap/all'

gsap.registerPlugin(CSSPlugin, ScrollToPlugin, ScrollTrigger, Draggable, InertiaPlugin)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
