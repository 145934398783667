import Vue from 'vue';
import * as firebase from "firebase";

// Required for side-effects
require("firebase/firestore");
class FirebaseService{


  public get db(){
    return firebase.firestore();
  }

  public get auth(){
    return firebase.auth();
  }
  
  constructor(){

  }

  initialize(){

    var config = {
      apiKey: "AIzaSyDTI2F4M9VnYZQps_HhdMaKE9Hlsn6x_cM",
      authDomain: "insta-like-fr.firebaseapp.com",
      databaseURL: "https://insta-like-fr.firebaseio.com",
      projectId: "insta-like-fr",
      storageBucket: "insta-like-fr.appspot.com",
      messagingSenderId: "572471160451",
      appId: "1:572471160451:web:6f19cae6d4d4b2bb7135b6"
    };
    
    firebase.initializeApp(config);
    
  }


  private static _singleton:FirebaseService;
  static get instance():FirebaseService {
    if(!FirebaseService._singleton) {
      FirebaseService._singleton = Vue.observable(new FirebaseService());
      FirebaseService._singleton.initialize();
    }
    return FirebaseService._singleton;
  }
}

export default FirebaseService.instance;